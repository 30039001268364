import axios from 'axios';
import { useEffect, useState } from 'react';
import {
  Card,
  Container,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  FormLabel,
  DialogActions,
  Box,
  Autocomplete,
  Divider
} from '@mui/material';
import { useDispatch } from '../../redux/store';
import useSettings from '../../hooks/useSettings';
import Page from '../../components/Page';
import todosUsuarios from 'src/actions/clientes/todosUsuarios';
import listaSetores from 'src/actions/clientes/listaSetores';
import listaTodosUsuariosBossByClienteId from 'src/actions/usuarios/listaTodosUsuariosBossByClienteId';
import criarChat from 'src/actions/criarChat';
import actionAdminActionCliente from 'src/actions/clientes/actionAdminActionCliente';

// React-Toastify imports
import { toast } from 'react-toastify';
import useAuth from 'src/hooks/useAuth';

export default function Chat() {
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();
  const {user} = useAuth();

  const [clienteId, setClientId] = useState(null);
  const [numero, setNumero] = useState('');
  const [nomeChat, setNomeChat] = useState('');
  const [listaClientes, setListaClientes] = useState([]);
  const [usuarios, setUsuarios] = useState([]);
  const [setores, setSetores] = useState([]);
  const [statusDirecionamento, setStatusDirecionamento] = useState('');
  const [webhookType, setWebhookType] = useState('');
  const [open, setOpen] = useState(false);

  const [usuarioId, setUsuarioId] = useState('');
  const [setorId, setSetorId] = useState('');

  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    todosUsuarios({ provedor: user.provedor }).then(res => {
      setListaClientes(res.data.clientes);
    });
  }, []);

  useEffect(() => {
 
      listaTodosUsuariosBossByClienteId(clienteId)
        .then(response => {
          const data = response.data;
          console.log('Dados retornados pela API de usuários:', data);
          setUsuarios(data.usuarios || []);
        })
        .catch(error => {
          console.error('Erro ao buscar usuários:', error);
          setUsuarios([]);
        });

      listaSetores({ clienteId:clienteId?._id })
        .then(response => {
          const data = response.data;
          console.log('Dados retornados pela API de setores:', data);
          setSetores(data.setores || []);
        })
        .catch(error => {
          console.error('Erro ao buscar setores:', error);
          setSetores([]);
        });

  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClearFields = () => {
    setClientId('');
    setNumero('');
    setNomeChat('');
    setUsuarioId('');
    setSetorId('');
    setStatusDirecionamento('');
    setWebhookType('');
    toast.info('Campos limpos');
  };

  const handleSubmit = async () => {
    console.log('Número antes de enviar:', numero);

    try {
      let data = {
        statusRoom: statusDirecionamento,
        chatbot: webhookType === "ativado",
        numeroCliente: numero,
        clienteId: clienteId?._id,
        direcionaUsuarios: [usuarioId],
        direcionaSetores: setorId ? [setorId] : [],
        nomeChat: nomeChat || listaClientes.find(cliente => cliente._id === clienteId?._id)?.nome || '',
        fotoPerfil: "",
        lastMessage: "",
      };
      console.log('o que vem de data', data);
      const response = await criarChat(data);
      toast.success('Chat criado com sucesso!');
      console.log('Resposta da API:', response.data);
      setClientId(null)
      handleClose();
    } catch (error) {
      toast.error('Erro ao criar o chat');
      console.error('Erro ao enviar a requisição:', error);
    }
  };

  const handleChangeStatusDirecionamento = (event) => {
    setStatusDirecionamento(event.target.value);
    console.log('Status Direcionamento selecionado:', event.target.value);
  };

  async function handleAdminAction(action) {
    try {
      let data = {
        scope: {
          action: action,
          numero: numero,
        }
      };
      await actionAdminActionCliente(clienteId?._id, data);
      toast.success('Sucesso');
      setClientId(null);
      setNumero('');
    } catch (error) {
      toast.error('Erro ao executar ação');
    }
  }

  return (
    <Page title="Chat">
      <Container maxWidth={'xl'}>
        <Box sx={{ height: '72vh', display: 'flex', p: 2 }}>
          <Stack direction={'column'} spacing={4}>
            <Stack spacing={2} direction={"column"}>
              <Stack spacing={2} direction={"row"}>
                <TextField size="small" label="Número" value={numero} onChange={e => setNumero(e.target.value)} />
                <Autocomplete
                  value={clienteId}
                  size="small"
                  onChange={(event, newValue) => {
                    setClientId(newValue);
                  }}
                  getOptionLabel={(i) => i.nome}
                  inputValue={inputValue}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  id="controllable-states-demo"
                  options={listaClientes}
                  sx={{ width: 300 }}
                  renderInput={(params) => <TextField {...params} label="Selecione o canal" />}
                />
       

              </Stack>

              <Stack direction={"row"} spacing={1}>

                <Button onClick={() => handleAdminAction('apagar_mensagens')} variant='contained'>Apagar mensagens</Button>
                <Button onClick={() => handleAdminAction('bloquear_numero')} variant='contained'>Bloquear número</Button>
                <Button onClick={() => handleAdminAction('deletar_chat')} variant='contained'>Excluir chat</Button>
              </Stack>

            </Stack>

            <Divider />
            <Stack direction={"row"}>

              <Button onClick={handleClickOpen} variant="outlined"  >Criar chat</Button>
            </Stack>
          </Stack>
        </Box>
      </Container>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>Criar Chat</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Preencha as informações para criar um novo chat.
          </DialogContentText>
          <Stack spacing={2} mt={2}>
            <TextField
              size="small"
              label="Número"
              value={numero}
              onChange={e => {
                setNumero(e.target.value);
                console.log('Número atualizado:', e.target.value);
              }}
            />
            <TextField
              size="small"
              label="Nome do Chat"
              value={nomeChat}
              onChange={e => {
                setNomeChat(e.target.value);
                console.log('Nome do Chat:', e.target.value);
              }}
            />
           <Autocomplete
                  value={clienteId}
                  size="small"
                  onChange={(event, newValue) => {
                    setClientId(newValue);
                  }}
                  getOptionLabel={(i) => i.nome}
                  inputValue={inputValue}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  id="controllable-states-demo"
                  options={listaClientes}
                  sx={{ width: 300 }}
                  renderInput={(params) => <TextField {...params} label="Selecione o canal" />}
                />
            <FormControl fullWidth margin="dense">
              <Autocomplete
                multiple
                id="autocomplete-usuarios"
                options={usuarios}
                getOptionLabel={(usuario) => usuario.nomeFantasia}
                value={usuarios.filter((usuario) => usuarioId.includes(usuario._id)) || []}
                onChange={(event, newValue) => {
                  setUsuarioId(newValue.map((usuario) => usuario._id));
                  console.log('Usuários selecionados:', newValue.map((usuario) => usuario._id));
                }}
                renderInput={(params) => <TextField {...params} label="Selecionar Usuários" />}
              />
            </FormControl>

            <FormControl fullWidth margin="dense">
              <Autocomplete
                multiple
                id="autocomplete-setores"
                options={setores}
                getOptionLabel={(setor) => setor.nome_setor}
                value={setores.filter((setor) => setorId.includes(setor._id)) || []}
                onChange={(event, newValue) => {
                  setSetorId(newValue.map((setor) => setor._id));
                }}
                renderInput={(params) => <TextField {...params} label="Selecionar Setores" />}
                disabled={statusDirecionamento === 'Atendendo'} // Desabilitar o campo se o status for "Atendendo"
              />
            </FormControl>

            <FormControl fullWidth margin="dense">
              <InputLabel id="demo-simple-select-label">Status Direcionamento</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={statusDirecionamento}
                onChange={handleChangeStatusDirecionamento}
              >
                <MenuItem value={"Pendente"}>Pendente</MenuItem>
                <MenuItem value={"Geral"}>Geral</MenuItem>
                <MenuItem value={"Atendendo"}>Atendendo</MenuItem>
                <MenuItem value={"Finalizado"}>Finalizado</MenuItem>
              </Select>
            </FormControl>

            {(statusDirecionamento === 'Geral' || statusDirecionamento === 'Finalizado') && (
              <FormControl component="fieldset">
                <FormLabel component="legend">Chatbot</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={webhookType}
                  onChange={e => {
                    setWebhookType(e.target.value);
                    console.log('Chatbot Webhook Type selecionado:', e.target.value);
                  }}
                >
                  <FormControlLabel value="ativado" control={<Radio />} label="Ativado" />
                  <FormControlLabel value="desativado" control={<Radio />} label="Desativado" />
                </RadioGroup>
              </FormControl>
            )}

          </Stack>
        </DialogContent>
        <DialogActions>
          <Box sx={{ flex: '1 1 auto' }}>
            <Button onClick={handleClearFields} variant='outlined'>
              Limpar Campos
            </Button>
          </Box>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Criar
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
}