import axios from "axios";
import Config from "../../config";
import editarCliente from "../editarCliente";

const actionEditarCliente = (orderId, data) => {
    
    return axios({
        method: "post",
        url: `${Config.dev ? Config.api_dev : Config.api_prod}/api/v1/clientes/cliente/${orderId}/editar`,
        data
    });
};

export default actionEditarCliente;
