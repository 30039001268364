import axios from "axios";
import Config from "src/config";

const v2_actionListarClientesHub = (query) => {
    return axios({
        method: "get",
        url: `${Config.dev ? Config.api_dev : Config.api_prod}/api/v2/admin/clientes-hub${query ? query : ''}`
    });
};

export default v2_actionListarClientesHub;
